import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M17.515 3.281a9.204 9.204 0 0 0-7.42 14.651l-6.814 6.815 1.972 1.972 6.815-6.815a9.204 9.204 0 1 0 5.447-16.623Zm0 2.79a6.415 6.415 0 1 1 0 12.829 6.415 6.415 0 0 1 0-12.83Z"
    />
  </svg>
);
export default SvgSearch;
