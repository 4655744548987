import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m7.114 5.625.8.375L15 12.647 22.086 6l.8-.375h3.199l.4.375v.375l-7.197 6.75-1.284 1.264-.66.681.66.635 1.284 1.17 7.196 6.75V24l-.4.375h-3.198l-.8-.375L15 17.353 7.914 24l-.8.375H3.915l-.4-.375v-.375l7.197-6.75 1.284-1.264.66-.681-.66-.635-1.284-1.17-7.196-6.75V6l.4-.375h3.198Z"
    />
  </svg>
);
export default SvgClose;
