import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={13.5} stroke="currentColor" strokeWidth={3} />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M20 11.282C20 8.577 17.955 7 15 7c-3.41 0-5 2.028-5 4.958l.227.225h1.818l.228-.225c0-1.803.682-2.93 2.727-2.93s2.727 1.127 2.727 2.254c0 1.577-.909 2.253-2.272 3.38-1.364 1.127-1.819 2.028-1.819 3.831l.228.225h1.818l.227-.225c0-1.352.455-1.803 1.818-2.93C19.091 14.437 20 13.535 20 11.282Zm-3.864 11.493V20.52l-.227-.225h-2.273l-.227.225v2.254l.227.225h2.273l.227-.225Z"
      />
    </g>
  </svg>
);
export default SvgQuestion;
