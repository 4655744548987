import { Tokens } from '.';

export const baseTokens: Omit<Tokens, 'colors'> = {
  breakpoints: {
    s: 580,
    m: 768,
    l: 1340
  },
  maxwidth: 1680,
  fontFamilies: {
    default: '"Jost", sans-serif'
  },
  typography: {
    'headline-1': {
      fontSizes: [29, 45],
      lineHeights: [1, 1],
      weight: 600
    },
    'headline-2': {
      fontSizes: [24, 28],
      lineHeights: [1.5, 1.5],
      weight: 600
    },
    'headline-3': {
      fontSizes: [20, 32],
      lineHeights: [1.3, 1.3],
      weight: 600
    },
    'headline-4': {
      fontSizes: [17, 24],
      lineHeights: [1.33, 1.33],
      weight: 500
    },
    copy: {
      fontSizes: [14, 16],
      lineHeights: [1.5, 1.5],
      letterSpacings: [0.2, 0.2]
    },
    'copy-large': {
      fontSizes: [18, 22],
      lineHeights: [1.5, 1.5],
      letterSpacings: [-0.2, -0.2]
    },
    'copy-small': {
      fontSizes: [12, 14],
      lineHeights: [1.5, 1.5]
    },
    hint: {
      fontSizes: [12, 12],
      lineHeights: [1.5, 1.5]
    },
    link: {
      fontSizes: [14, 16],
      lineHeights: [1, 1]
    },
    button: {
      fontSizes: [14, 18],
      lineHeights: [1.1, 1.1],
      letterSpacings: [0.2, 0.2],
      weight: 500
    },
    'form-label': {
      fontSizes: [12, 14],
      lineHeights: [1.5, 1.5],
      letterSpacings: [0.5, 0.5],
      weight: 500
    },
    'form-value': {
      fontSizes: [16, 16],
      lineHeights: [1, 1]
    },
    'form-hint': {
      fontSizes: [10, 12],
      lineHeights: [1, 1]
    }
  },
  grid: {
    default: {
      columns: 4,
      columnGap: 12
    },
    s: {
      columns: 4,
      columnGap: 20
    },
    m: {
      columns: 12,
      columnGap: 30
    },
    l: {
      columns: 12,
      columnGap: 40
    }
  },
  spacings: {
    xxs: '4px',
    xs: '10px',
    s: '12px',
    m: '24px',
    l: '40px',
    xl: '48px',
    xxl: '60px',
    xxxl: '80px'
  }
};

const defaultTokens: Tokens = {
  ...baseTokens,
  colors: {
    neutral: {
      default: '90',
      '10': '#ffffff',
      '30': '#eeeeee',
      '50': '#979797',
      '70': '#434343',
      '90': '#1D1D1B'
    },

    base: {
      default: '50',
      '50': '#F8F5F2'
    },
    primary: {
      default: '50',
      '10': '#484d72', // for lines
      '20': '#8589a6',
      '50': '#293056',
      '70': '#293056',
      '90': '#293056'
    },
    accent1: {
      default: '50',
      '50': '#6BDBA1'
    },
    warning: {
      default: '50',
      '50': '#ff0000'
    },
    notification: {
      default: '50',
      '50': '#ffe200'
    },
    success: {
      default: '50',
      '50': '#00b30d'
    },
    interaction: {
      default: '50',
      '50': '#2400fe'
    }
  }
};

const tokens = {
  default: defaultTokens
};

export default tokens;
