import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExternal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m21.858 20.835-.546.005-.26-.265.431-9.652-.261-.266-15.11 14.338-.545.005L4 23.406l.024-.536 15.11-14.34-.262-.265-9.83.087-.262-.266.024-.536 2.28-2.164.832-.276L24.478 5l.522.531-.552 12.333-.309.807z"
    />
  </svg>
);
export default SvgExternal;
