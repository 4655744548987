import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...props}>
    <path
      fill="currentColor"
      d="M140 40v20h60v20h-20v120H60V80H40V60h60V40h40Zm20 40H80v100h80V80Zm-20 20v60h-10v-60h10Zm-30 0v60h-10v-60h10Z"
    />
  </svg>
);
export default SvgDelete;
