import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m24.375 8.603-.375-.4h-.375l-6.75 7.197-1.17 1.283-.635.66-.68-.66-1.265-1.283-6.75-7.197H6l-.375.4V11.8l.375.8 8.625 9.196h.75L24 12.6l.375-.8z"
    />
  </svg>
);
export default SvgExpand;
