import * as React from 'react';
import type { SVGProps } from 'react';
const SvgImageUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M11 10v1.5H2.5v16h.433l7.424-8.958 5.89 7.681 4.29-4.527 6.011 5.804h.952v-16H19V10h10v19H1V10h10Zm11 4a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM15.24 1l4.521 4.257.238.457v1.829l-.238.228h-.24L16.2 4.656h-.241v13.115l-.238.229H14.28l-.24-.229V4.656h-.24l-3.32 3.115h-.24L10 7.543V5.714l.24-.457L14.761 1h.479Z"
    />
  </svg>
);
export default SvgImageUpload;
