import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 84" {...props}>
    <g fill="none" stroke="currentColor">
      <path d="M15.995 68.336 53.558 82 40.076 42 4.364 43.182z" />
      <path d="M47.643 2 80 23.303l-9.69 45.613L53.558 82 40.076 42z" />
      <path d="M4.364 43.182 47.643 2l-7.567 40z" />
    </g>
  </svg>
);
export default SvgStone;
