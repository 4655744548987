import { AnimatePresence, motion } from 'framer-motion';

import { useNotificationStore } from '../../store/notifications';

import Icon, { IconTypes } from '../Icon';
import Text from '../Text';
import { useState } from 'react';
import { AnimatedProgress, NotificationList, NotificationWrapper } from './Notifications.style';

export type NotificationAppearance = 'success' | 'error' | 'default';

export type NotificationProps = {
  title?: string;
  text?: string;
  appearance?: NotificationAppearance;
  icon?: IconTypes;
  alignment?: 'center' | 'flex-start';
  duration?: number;
  onDurationEnd?: () => void;
  onClose?: () => void;
};

const Notification = ({
  text,
  title,
  icon,
  duration,
  onDurationEnd,
  onClose
}: NotificationProps) => {
  const [stopped, setStopped] = useState(false);

  return (
    <NotificationWrapper
      onMouseEnter={() => setStopped(true)}
      onMouseLeave={() => setStopped(false)}
      role="alert"
    >
      {icon && <Icon icon={icon} size="small" />}

      {title && (
        <Text variant="copy" weight="medium">
          {title}
        </Text>
      )}

      {text && <Text>{text}</Text>}

      {onClose && (
        <button type="button" onClick={onClose}>
          <Icon size="tiny" icon="Close" />
        </button>
      )}

      {onDurationEnd && duration && (
        <AnimatedProgress $duration={duration} $stopped={stopped} onAnimationEnd={onDurationEnd} />
      )}
    </NotificationWrapper>
  );
};

const Notifications = () => {
  const removeNotification = useNotificationStore(state => state.removeNotification);
  const notifications = useNotificationStore(state => state.notifications);

  return (
    <NotificationList>
      <AnimatePresence initial={false}>
        {notifications.map(notification => (
          <motion.li
            key={notification.id}
            layout
            initial={{ opacity: 0, y: 50, scale: 0.8 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.2 }}
          >
            <Notification
              {...notification}
              onClose={() => removeNotification(notification.id)}
              duration={notification.duration || 10}
              onDurationEnd={
                notification.hideOn !== 'close'
                  ? () => removeNotification(notification.id)
                  : undefined
              }
            />
          </motion.li>
        ))}
      </AnimatePresence>
    </NotificationList>
  );
};

export default Notifications;
