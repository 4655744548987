import styled, { css } from 'styled-components';
import { space, variant } from '../../stylesheets';

const StyledIcon = styled.svg<{ size: 'supertiny' | 'tiny' | 'small' | 'large' }>`
  ${variant({
    prop: 'size',
    supertiny: css`
      width: 14px;
      height: 14px;
    `,
    tiny: css`
      width: 20px;
      height: 20px;
    `,
    small: css`
      width: ${space('m')};
      height: ${space('m')};
    `,
    large: css`
      height: ${space('l')};
      width: ${space('l')};
    `
  })}
`;

export default StyledIcon;
