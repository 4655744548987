import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWhatsApp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M20.853 17.37c-.05-.027-1.937-1.034-2.272-1.165a1.22 1.22 0 0 0-.44-.105c-.254 0-.468.137-.635.408-.189.304-.76 1.027-.936 1.243-.023.028-.054.063-.073.063-.017 0-.309-.13-.397-.172-2.024-.954-3.56-3.247-3.77-3.633-.031-.056-.032-.081-.033-.081.008-.03.076-.104.111-.142.103-.11.214-.255.322-.396.05-.067.102-.133.152-.196.156-.198.226-.35.307-.528l.042-.092a1.024 1.024 0 0 0-.026-.898c-.044-.097-.841-2.182-.926-2.401-.203-.529-.473-.775-.847-.775-.034 0 0 0-.145.007-.177.008-1.142.146-1.569.437-.453.31-1.218 1.296-1.218 3.03 0 1.561.913 3.035 1.306 3.595l.053.084c1.502 2.379 3.374 4.142 5.272 4.964 1.827.791 2.693.883 3.184.883.207 0 .373-.018.519-.033l.092-.01c.632-.06 2.019-.84 2.334-1.79.249-.75.314-1.568.149-1.865-.113-.202-.309-.304-.556-.432Z"
    />
    <path
      fill="currentColor"
      d="M15.23 2C8.19 2 2.46 7.686 2.46 14.674c0 2.26.605 4.473 1.751 6.41l-2.193 6.47a.338.338 0 0 0 .422.43l6.746-2.144a12.857 12.857 0 0 0 6.045 1.507C22.27 27.347 28 21.662 28 14.674S22.272 2 15.23 2Zm0 22.707c-1.986 0-3.91-.573-5.565-1.66a.338.338 0 0 0-.288-.038l-3.38 1.074 1.092-3.219a.338.338 0 0 0-.048-.308 9.896 9.896 0 0 1-1.925-5.882C5.116 9.142 9.653 4.64 15.23 4.64c5.577 0 10.114 4.502 10.114 10.034 0 5.533-4.537 10.033-10.114 10.033Z"
    />
  </svg>
);
export default SvgWhatsApp;
