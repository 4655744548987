import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReset = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m10.266 8.786.47.228L15 13.17l4.265-4.155.469-.228h1.876l.235.228v.229l-4.222 4.114-.753.77-.388.416.388.386.753.714 4.222 4.114v.229l-.235.228h-1.876l-.47-.228L15 15.83l-4.265 4.156-.469.228H8.39l-.235-.228v-.229l4.222-4.114.753-.77.388-.416-.388-.386-.753-.714-4.222-4.114v-.229l.235-.228h1.876Z"
    />
  </svg>
);
export default SvgReset;
