import * as React from 'react';
import type { SVGProps } from 'react';
const SvgForward = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m8.603 5.625-.4.375v.375l7.197 6.75 1.283 1.17.66.635-.66.68-1.283 1.265-7.197 6.75V24l.4.375H11.8l.8-.375 9.196-8.625v-.75L12.6 6l-.8-.375z"
    />
  </svg>
);
export default SvgForward;
