import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M130 60v49.999l50 .001v20l-50-.001V180h-20l-.001-50.001L60 130v-20l50-.001V60z"
    />
  </svg>
);
export default SvgAdd;
