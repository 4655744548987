import NProgress from 'nprogress';

let progressBarTimeout: NodeJS.Timeout | null = null;

NProgress.configure({ showSpinner: false });

const clearProgressBarTimeout = () => {
  if (progressBarTimeout) {
    clearTimeout(progressBarTimeout);
    progressBarTimeout = null;
  }
};

export const startProgressBar = () => {
  clearProgressBarTimeout();
  progressBarTimeout = setTimeout(() => NProgress.start(), 200);
};

export const stopProgressBar = () => {
  clearProgressBarTimeout();
  NProgress.done();
};
