import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTextUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.181 1 4.844 4.774v10.732l8.205 8.206-4.294 4.296-3.911-3.911V29H1V1h15.181Zm-.738 1.8H2.801v24.4h16.424v-3.902l-5.299-5.3-1.242-4.36H5.333v-1.8h6.804l.069.124-.039-.137 6.063 1.885.995.995V6.529l-3.784-.002.002-3.726Zm-.78 11.582.777 2.725 9.496 9.497 1.89-1.892-9.503-9.504-2.66-.826Zm-1.012-6.188v1.8H5.333v-1.8h8.318Z"
    />
  </svg>
);
export default SvgTextUpload;
