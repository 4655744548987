import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m5.625 21.397.375.4h.375l6.75-7.197 1.17-1.283.635-.66.68.66 1.265 1.283 6.75 7.197H24l.375-.4V18.2L24 17.4l-8.625-9.196h-.75L6 17.4l-.375.8z"
    />
  </svg>
);
export default SvgCollapse;
