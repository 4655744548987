import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m15.857 25-.428-.4v-.4l7.714-7.2v-.4H.429L0 16.2v-2.4l.429-.4h22.714V13l-7.714-7.2v-.4l.428-.4h3.429l.857.4L30 14.6v.8l-9.857 9.2-.857.4z"
    />
  </svg>
);
export default SvgRight;
