import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...props}>
    <path
      fill="currentColor"
      d="m188.9 49.91 22.2 20.18-109.665 120.631-71.038-59.198 19.206-23.046 48.961 40.801z"
    />
  </svg>
);
export default SvgCheckmark;
