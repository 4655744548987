import styled, { keyframes } from 'styled-components';
import { color, space } from '../../stylesheets';

export const NotificationList = styled.ul`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  max-width: 550px;
  width: 100%;
  list-style-type: none;
  margin: 0;
  margin-bottom: ${space('s')};
  padding: 0 ${space('m')};
  z-index: 9999;

  li {
    margin-bottom: ${space('s')};
  }
`;

const animation = keyframes`
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
`;

export const NotificationWrapper = styled.div`
  position: relative;
  padding: ${space('s')};
  width: 100%;
  background: ${color('neutral', 10)};
  color: ${color('primary')};
  border: 2px solid ${color('primary')};
  overflow: hidden;

  button {
    position: absolute;
    top: ${space('xs')};
    right: ${space('xs')};
  }
`;

export const AnimatedProgress = styled.div<{
  $duration: number;
  $stopped: boolean;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  transform-origin: left center;
  background: ${color('primary')};
  animation: ${animation} ${props => props.$duration}s linear forwards;
  animation-play-state: ${props => (props.$stopped ? 'paused' : 'running')};
`;
