import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownward = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m25 14.143-.4.428h-.4L17 6.857h-.4v22.714l-.4.429h-2.4l-.4-.429V6.857H13l-7.2 7.714h-.4l-.4-.428v-3.429l.4-.857L14.6 0h.8l9.2 9.857.4.857z"
    />
  </svg>
);
export default SvgDownward;
