
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable react/no-unknown-property */
import type { AppProps } from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import 'keen-slider/keen-slider.min.css';
import { startProgressBar, stopProgressBar } from '../lib/progress-bar';
import Notifications from '../components/Notifications';
import GlobalStyle from '../stylesheets/global';
import { ThemeProvider } from '../stylesheets';
import { useEffect, useState } from 'react';
declare global {
    interface Window {
        _paq: any;
        CookieFirst: {
            acceptCategory: (cookiefirstCategory: 'advertising' | 'functional') => void;
            renderEmbeds: () => void;
            consent: {
                necessary: boolean;
                performance: boolean;
                functional: boolean;
                advertising: boolean;
            };
        };
    }
}
const push = (args: any[]): void => {
    if (!window._paq) {
        window._paq = [];
    }
    window._paq.push(args);
};
let previousPath: string | undefined = undefined;
Router.events.on('routeChangeError', () => stopProgressBar());
Router.events.on('routeChangeStart', (path: string): void => {
    startProgressBar();
    if (!window._paq)
        return;
    const [pathname] = path.split('?');
    if (previousPath) {
        push(['setReferrerUrl', previousPath]);
    }
    push(['setCustomUrl', pathname]);
    push(['deleteCustomVariables', 'page']);
    previousPath = pathname;
});
Router.events.on('routeChangeComplete', (path: string): void => {
    stopProgressBar();
    if (!window._paq)
        return;
    setTimeout(() => {
        const { q } = Router.query;
        push(['setDocumentTitle', document.title]);
        if (path.startsWith('/recherche') || path.startsWith('/search')) {
            push(['trackSiteSearch', q ?? '']);
        }
        else {
            push(['trackPageView']);
        }
    }, 0);
});
function MyApp({ Component, pageProps }: AppProps) {
    const [consent, setConsent] = useState(false);
    const handleConsent = () => setConsent(window?.CookieFirst?.consent?.advertising);
    useEffect(() => {
        window.addEventListener('cf_init', handleConsent);
        window.addEventListener('cf_consent', handleConsent);
        return () => {
            window.removeEventListener('cf_init', handleConsent);
            window.removeEventListener('cf_consent', handleConsent);
        };
    }, [consent]);
    useEffect(() => {
        if (window && window.CookieFirst) {
            setConsent(window.CookieFirst.consent.advertising);
        }
    }, []);
    return (<ThemeProvider>
      <GlobalStyle />

      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#293056"/>
        <meta name="msapplication-TileColor" content="#fff"/>
        <meta name="theme-color" content="#fff"/>
      </Head>

      <Script src="https://consent.cookiefirst.com/sites/shoah-memorial-frankfurt.de-60688d7a-8eb8-4260-8609-0d79d87d5720/consent.js"/>

      <Component {...pageProps}/>

      <Notifications />

      {consent && (<Script id="matomo" dangerouslySetInnerHTML={{
                __html: `
              var _paq = _paq || [];
              /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
              _paq.push(["setDoNotTrack", true]);
              _paq.push(["disableCookies"]);
              _paq.push(['trackPageView']);
              _paq.push(['enableLinkTracking']);
              (function() {
                var u="//matomo.juedischesmuseum.de/";
                _paq.push(['setTrackerUrl', u+'piwik.php']);
                _paq.push(['setSiteId', '5']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
              })();
            `
            }}/>)}

      <style jsx global>{`
        @font-face {
          font-family: 'Jost';
          font-display: swap;
          font-style: normal;
          src: url('/fonts/jost.woff2') format('woff2');
        }
      `}</style>
    </ThemeProvider>);
}
const __Next_Translate__Page__18d831d09da__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18d831d09da__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  