import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M27.16 2.523H2.405L1.697 4.23l9.03 9.029v7.234l.293.707 6.11 6.11 1.708-.706V13.259l9.03-9.029-.707-1.707Zm-2.414 2-7.615 7.616-.293.707v11.343l-4.112-4.111.001-7.232-.293-.707-7.616-7.616h19.928Z"
    />
  </svg>
);
export default SvgFilter;
