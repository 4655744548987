import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m20.005 4 6.68.008.315.374v2.244L26.685 7H9.995l-6.68-.008L3 6.618V4.374L3.315 4zm0 9 6.68.008.315.374v2.244l-.315.374H9.995l-6.68-.008L3 15.618v-2.244L3.315 13zm-.038 9 6.719.008.314.374v2.244l-.314.374H10.033l-6.719-.008L3 24.618v-2.244L3.314 22z"
    />
  </svg>
);
export default SvgMenu;
