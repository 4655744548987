import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUpward = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m25 15.857-.4-.428h-.4L17 23.143h-.4V.429L16.2 0h-2.4l-.4.429v22.714H13l-7.2-7.714h-.4l-.4.428v3.429l.4.857L14.6 30h.8l9.2-9.857.4-.857z"
    />
  </svg>
);
export default SvgUpward;
