import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBackward = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="m21.397 24.375.4-.375v-.375l-7.197-6.75-1.283-1.17-.66-.635.66-.68 1.283-1.265 7.197-6.75V6l-.4-.375H18.2L17.4 6l-9.196 8.625v.75L17.4 24l.8.375z"
    />
  </svg>
);
export default SvgBackward;
