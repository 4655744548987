import * as React from 'react';
import type { SVGProps } from 'react';
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M23 12a5 5 0 1 0-4.84-3.738l-7.173 3.523a5 5 0 1 0-.166 6.244l7.349 3.674a5 5 0 1 0 .895-1.788l-7.284-3.642a4.997 4.997 0 0 0 .07-2.684l7.192-3.532A4.992 4.992 0 0 0 23 12Z"
    />
  </svg>
);
export default SvgShare;
