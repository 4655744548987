import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M9.625 20.832c.46.46 1.206.46 1.666 0l9.997-9.997a1.178 1.178 0 1 0-1.666-1.666l-9.997 9.997c-.46.46-.46 1.206 0 1.666Z"
    />
    <path
      fill="currentColor"
      d="M4.626 16.666a6.48 6.48 0 0 0 9.164 9.164l4.582-4.581a1.178 1.178 0 0 0-1.666-1.667l-4.582 4.582a4.123 4.123 0 1 1-5.832-5.831l4.582-4.582a1.178 1.178 0 0 0-1.666-1.666l-4.582 4.581ZM26.286 13.334l-4.582 4.582a1.178 1.178 0 1 1-1.666-1.666l4.582-4.582a4.123 4.123 0 1 0-5.832-5.831l-4.582 4.581a1.178 1.178 0 1 1-1.666-1.666l4.582-4.582a6.48 6.48 0 0 1 9.164 9.164Z"
    />
  </svg>
);
export default SvgLink;
