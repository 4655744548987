import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M26.824 2.998c1.664 1.498 1.522 3.418-.172 5.541l.01.012-.158.17c-.298.356-.64.72-1.023 1.086L10.41 25.835 3 28l2.246-7.409L20.72 4.325l.035-.044c2.022-2.46 4.166-2.998 6.07-1.283Zm-5.393 3.141L6.817 21.515 6.23 23.45l1.608 1.288 1.618-.473 14.68-15.63-2.705-2.495Zm1.2-1.308 2.723 2.512c1.071-1.35 1.096-2.297.286-3.026-.904-.813-1.826-.715-3.01.514Z"
    />
  </svg>
);
export default SvgEdit;
