import * as React from 'react';
import type { SVGProps } from 'react';
const SvgZoomOut = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M19.005 1C12.932 1 8.009 5.923 8.009 11.995c0 2.435.792 4.686 2.132 6.508L2 26.644 4.356 29l8.141-8.14a10.947 10.947 0 0 0 6.508 2.13C25.077 22.99 30 18.069 30 11.996 30 5.923 25.077 1 19.005 1Zm-.846 4.86h2.03v4.728h4.728v2.03h-4.728v4.728h-2.03V12.62H13.43v-2.03h4.728V5.86Zm.846-1.528a7.663 7.663 0 1 1 0 15.327 7.663 7.663 0 0 1 0-15.327Z"
    />
  </svg>
);
export default SvgZoomOut;
