import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={13.5} stroke="currentColor" strokeWidth={3} />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="m16.492 17.185-.374.315h-2.244l-.374-.315.008-10.37.374-.315h2.244l.374.315zm0 6-.374.315h-2.244l-.374-.315.008-3.37.374-.315h2.244l.374.315z"
      />
    </g>
  </svg>
);
export default SvgInfo;
