import { create } from 'zustand';
import { nanoid } from 'nanoid';

import { IconTypes } from '../components/Icon';

export type NotificationAppearance = 'error' | 'success' | 'default';

export type Notification = {
  id: string;
  title?: string;
  text?: string;
  appearance?: NotificationAppearance;
  duration?: number;
  hideOn?: 'close' | 'progress';
  icon?: IconTypes;
};

type NotificationStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  removeNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationStore>(set => ({
  notifications: [],
  addNotification: notification => {
    set(state => {
      const notifications = [
        ...state.notifications,
        { ...notification, hideOn: notification.hideOn || 'progress', id: nanoid(4) }
      ];
      if (notifications.length > 7) notifications.shift();
      return { notifications };
    });
  },
  removeNotification: (id: string) => {
    set(state => ({ notifications: state.notifications.filter(i => i.id !== id) }));
  }
}));
