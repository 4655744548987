import * as React from 'react';
import type { SVGProps } from 'react';
const SvgZoomStone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.128 3 24 4.81l-3.531 16.45-1.916 1.913L12.458 25 3 15.555l2.4-9.587L13.13 3h-.002Zm.128 1.26-3.93 1.51v7.042l8.161-7.848-4.231-.704Zm5.736.955L9.79 14.063l10.005 4.496 2.738-12.754-3.542-.59Zm.542 14.567-9.324-4.19 4.387 7.49 3.308-.99 1.442-1.44.187-.87Zm-6.144 3.663-4.978-8.502-3.513.78 7.908 7.896.584-.174h-.001Zm-8.88-8.889 1.922-7.675 1.67-.641v7.52l-3.591.796Z"
    />
  </svg>
);
export default SvgZoomStone;
