import { ReactNode } from 'react';
import styled from 'styled-components';

import { color, typography } from '../../stylesheets';
import { Colors, Typography } from '../../tokens';

export type TextWeight = 'semibold' | 'medium' | 'normal';

export type TextProps = {
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'figure'
    | 'div'
    | 'cite'
    | 'label'
    | 'a';
  variant?: Typography;
  children?: ReactNode;
  transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  weight?: TextWeight;
  color?: Colors;
  shade?: number;
  dangerouslySetInnerHtml?: any;
};

const getWeights = (weight: TextWeight) => {
  const weights = {
    normal: 400,
    medium: 500,
    semibold: 600
  };

  return weights[weight];
};

const Text = styled.p<TextProps>`
  color: ${props => (props.color ? color(props.color, props.shade) : 'inherit')};
  margin: 0;
  text-transform: ${props => props.transform};
  ${props => typography(props.variant || 'copy')(props)}

  ${props => (props.weight ? `font-variation-settings: "wght" ${getWeights(props.weight)};` : '')}
`;

export default Text;
