import * as React from 'react';
import type { SVGProps } from 'react';
const SvgZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M18.005 1C11.932 1 7.009 5.923 7.009 11.995c0 2.435.792 4.686 2.132 6.508L1 26.644 3.356 29l8.141-8.14a10.947 10.947 0 0 0 6.508 2.13C24.077 22.99 29 18.069 29 11.996 29 5.923 24.077 1 18.005 1ZM12.43 11.186h11.486v2.03H12.431v-2.03Zm5.574-6.854a7.663 7.663 0 1 1 0 15.327 7.663 7.663 0 0 1 0-15.327Z"
    />
  </svg>
);
export default SvgZoomIn;
